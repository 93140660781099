export default function getBrowserName() {
  const userAgent = navigator.userAgent

  if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    return 'Opera'
  }

  if (userAgent.includes('Edg')) {
    return 'Edge'
  }

  if (userAgent.includes('Firefox')) {
    return 'Firefox'
  }

  if (userAgent.includes('Chrome')) {
    return 'Chrome'
  }

  if (userAgent.includes('Safari')) {
    return 'Safari'
  }

  return 'Unknown'
}
