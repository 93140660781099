export default function validTime(timeString = ''): boolean {
  const [hours = '00', minutes = '00', seconds = '00'] = timeString.split(':')

  if (hours.length > 2 || minutes.length > 2 || seconds.length > 2) {
    return false
  }

  return (
    +hours >= 0 &&
    +hours <= 23 &&
    +minutes >= 0 &&
    +minutes <= 59 &&
    +seconds >= 0 &&
    +seconds <= 59
  )
}
