export default function loadScript(
  src: string,
  async = true,
  crossOrigin = ''
): HTMLScriptElement {
  const script = document.createElement('script')

  script.src = src
  script.async = async

  if (crossOrigin) {
    script.crossOrigin = crossOrigin
  }

  document.body.appendChild(script)

  return script
}
