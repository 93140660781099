import cloneObject from './cloneObject'

export default function differenceObj(
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
): Record<string, unknown> {
  const diff = Object.keys(cloneObject({ ...obj1, ...obj2 })).reduce<
    Record<string, unknown>
  >((acc, key) => {
    if (obj1[key] !== obj2[key]) {
      if (obj2[key] !== undefined) {
        acc[key] = obj2[key]
      } else if (obj1[key] !== undefined) {
        acc[key] = obj1[key]
      }
    }

    return acc
  }, {})

  return diff
}
