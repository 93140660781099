export default function formatNumber(
  n: number,
  options: {
    thousandth?: boolean
    millionth?: boolean
    thousandLabel?: string
    millionLabel?: string
  } = {}
): string {
  if (!n) return '0'
  const {
    thousandth = true,
    millionth = true,
    millionLabel = 'млн.',
    thousandLabel = 'тыс.',
  } = options

  n = Math.ceil(n)

  if (millionth && n >= 1e6) {
    return `${+(n / 1e6).toFixed(1)} ${millionLabel}`
  }

  if (thousandth && n >= 1e3) {
    return `${+(n / 1e3).toFixed(1)} ${thousandLabel}`
  }

  return n.toLocaleString('ru-RU')
}
