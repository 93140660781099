import isEqual from 'lodash.isequal'
import transform from 'lodash.transform'
import isObject from './isObject'

export function deepDiff(
  after: Record<string, any>,
  before: Record<string, any>
): Record<string, any> {
  function changes(
    after: Record<string, any>,
    before: Record<string, any>
  ): Record<string, any> {
    return transform(after, function (result, value, key) {
      if (!isEqual(value, before[key])) {
        result[key] =
          isObject(value) &&
          isObject(before[key]) &&
          !Array.isArray(value) &&
          !Array.isArray(before[key])
            ? changes(value, before[key])
            : value
      }
    })
  }
  return changes(after, before)
}
