import isEmpty from './isEmpty'

export default function mergeStrings({
  values,
  separator = ' ',
}: {
  values: Array<string | null | undefined>
  separator?: string
}) {
  return values.filter((v) => !isEmpty(v)).join(separator)
}
