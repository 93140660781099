export default function downloadFile(
  value: Blob | File,
  {
    fileExtension,
    fileName,
  }: {
    fileExtension: string
    fileName: string
  }
): void {
  const link = document.createElement('a')
  link.href = URL.createObjectURL(value)
  link.setAttribute('download', `${fileName}.${fileExtension}`)
  link.click()

  URL.revokeObjectURL(link.href)
}
