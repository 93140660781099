import { Platform, ProjectID } from '@/types/platform'

const projectIds = Object.entries(ProjectID)

export const getPlatformById = (id: ProjectID) => {
  for (const [key, value] of projectIds) {
    if (typeof value !== 'string') {
      continue
    }

    const currentId = +key as ProjectID

    if (isNaN(currentId) || currentId !== id) {
      continue
    }

    const code = value as Platform

    return code
  }
}
