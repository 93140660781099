const userTimeZone = new Date().getTimezoneOffset() // часовой пояс пользователя в минутах
const moscowTimeZone = -180 // в минутах (UTC +3)

const checkMoscowTimeZone = () => {
  return moscowTimeZone === userTimeZone
}

export const getMoscowTimeDiff = () => {
  const now = new Date()
  const offsetDifference = userTimeZone - moscowTimeZone

  return new Date(now.getTime() + offsetDifference * 60 * 1000)
}

export const isMoscowTimeZone = checkMoscowTimeZone()
