export default function checkURL(value?: string | null) {
  if (!value) {
    return false
  }

  const inputElement = document.createElement('input')

  inputElement.type = 'url'
  inputElement.value = value

  return inputElement.checkValidity()
}
