import { parseDate } from '@/lib/date'

export function getLocaleLongMonth(date: string): string {
  return new Date(date).toLocaleDateString('ru-RU', {
    day: '2-digit',
    month: 'long',
  })
}

export function isValidDate(date: string | Date): boolean {
  return !isNaN(new Date(date).getTime())
}

export function formatArticleDate(
  val?: string | null,
  options: { showTime?: boolean } = {}
): string {
  if (!val) {
    return ''
  }

  const { showTime = true } = options
  const formatDate = parseDate(val, 'yyyy-MM-dd HH:mm:ss', new Date())
  const nowDate = new Date()

  if (formatDate.getFullYear() === nowDate.getFullYear()) {
    if (
      showTime &&
      formatDate.getMonth() === nowDate.getMonth() &&
      formatDate.getDate() === nowDate.getDate()
    ) {
      return formatDate.toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit',
      })
    }

    return formatDate.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: 'long',
      hour: showTime ? '2-digit' : undefined,
      minute: showTime ? '2-digit' : undefined,
    })
  }

  return formatDate.toLocaleString('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: showTime ? '2-digit' : undefined,
    minute: showTime ? '2-digit' : undefined,
  })
}
