import isNumber from './isNumber'

export default function numberToFixed(
  value?: number | string,
  fractionDigits = 2
): number {
  const localValue = value && isNumber(value) ? +value : 0
  const [wholePart, decimalPart = ''] = localValue.toFixed(8).split('.')

  return +[wholePart, decimalPart.slice(0, fractionDigits)].join('.')
}
